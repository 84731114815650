import React, { Component, type FC, type ReactNode, useContext, type ErrorInfo } from 'react';
import UFOInteractionContext, { type LabelStack } from '@atlaskit/react-ufo/interaction-context';
import UFOInteractionIDContext from '@atlaskit/react-ufo/interaction-id-context';
import { addError, addErrorToAll } from '@atlaskit/react-ufo/interaction-metrics';
import { ExperienceFailureTracker as ViewExperienceFailureTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import log, { type Event } from '@atlassian/jira-common-util-logging/src/log.tsx';
import { getErrorHash } from '@atlassian/jira-errors-handling/src/utils/error-hash.tsx';
import type { AnalyticsPayload } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { captureException as reportToSentry } from '@atlassian/jira-capture-exception/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { UnknownError } from './UnknownError.tsx';

export type CaughtError = {
	error: Error;
	info: ErrorInfo;
};
type ExtraEventData = {
	readonly [attributeKey: string]: string | boolean | number;
};
export type ExtraErrorAttributes = {
	readonly [attributeKey: string]: string | boolean | number | null;
};
export type ErrorBoundaryFallbackComponent = (props: { error: Error }) => ReactNode;
export interface Props extends Partial<AnalyticsPayload['meta']> {
	prefixOverride?: string;
	children?: ReactNode;
	extraEventData?: ExtraEventData;
	withExperienceTracker?: boolean;
	render?: ErrorBoundaryFallbackComponent;
	onError?: (location: string, error: Error, attributes: ExtraErrorAttributes) => void;
	// In case you caught an error thrown by this error boundary and wish to retry rendering, pass it as {resetCaughtError} prop to reset state
	resetCaughtError?: Error;
}
interface ReportErrorArgs extends Partial<AnalyticsPayload['meta']> {
	prefixOverride?: string;
	extraEventData?: ExtraEventData;
	onError?: Props['onError'];
}
type State = {
	caughtError: CaughtError | null;
};

const EGRESS_LIMIT = 5;
const getLocation = ({
	id,
	packageName,
	prefixOverride,
}: {
	id?: string;
	packageName?: string;
	prefixOverride?: string;
}) => {
	const prefix = prefixOverride != null ? prefixOverride : 'common';
	if (id != null) {
		return packageName != null
			? `${prefix}.error-boundary.${packageName}.${id}`
			: `${prefix}.error-boundary.${id}`;
	}
	return `${prefix}.error-boundary`;
};
type ErrorBoundaryInnerProps = Props & {
	ufoInteractionId: string | null;
	ufoLabelStack: LabelStack | null;
};
const ErrorBoundary: FC<Props> = (props) => {
	const interactionId = useContext(UFOInteractionIDContext);
	const interactionContext = useContext(UFOInteractionContext);
	return (
		<ErrorBoundaryInner
			{...props}
			ufoInteractionId={interactionId.current}
			ufoLabelStack={interactionContext?.labelStack}
		/>
	);
};

export function reportError(
	error: Error | undefined,
	info: ErrorInfo,
	{
		id,
		packageName,
		teamName,
		prefixOverride,
		onError,
		extraEventData = {},
	}: Readonly<ReportErrorArgs>,
	interactionId?: string | null,
	labelStack?: LabelStack | null,
) {
	// Remove this when cleaning `jfp-magma-fix-undefined-error-in-error-boundary`
	// @ts-expect-error - TS2345 - Argument of type 'Error | undefined' is not assignable to parameter of type 'Error'.
	let errorToReport: Error = error;

	if (fg('jfp-magma-fix-undefined-error-in-error-boundary')) {
		// Somehow, the ErrorBoundary component catch an undefined error.
		// So this check helps to reduce the noise in our Sentry.
		errorToReport = error ?? new UnknownError();
	}

	const hash = getErrorHash(errorToReport);

	const event = {
		teamName: teamName ?? null,
		...extraEventData,
		message: errorToReport.message ?? null,
		stack: errorToReport.stack ?? null,
		// FIXME type "ErrorInfo" used to be mistyped but the type fix propagates in a non-trivial way,
		// cf. PR https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/pull-requests/137436/
		// Progress over perfection: I'm casting to keep the above PR type-only.
		// Next dev actively changing this code, please remove the type cast and use selective inclusion instead.
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		componentStack: info.componentStack as any as ExtraErrorAttributes['componentStack'],
		hash: hash ?? null,
	};

	const location = getLocation({
		id,
		packageName,
		prefixOverride,
	});
	ErrorBoundaryInner.logErrorToSplunk(hash, location, event);

	reportToSentry(
		location,
		errorToReport,
		// @ts-expect-error TS2345: Index signature for type string is missing in type ErrorInfo
		info,
	);

	if (interactionId) {
		addError(
			interactionId,
			`JSErrorBoundary:${id}`,
			labelStack || null,
			errorToReport.name,
			errorToReport.message,
			errorToReport.stack,
		);
	} else {
		addErrorToAll(
			`JSErrorBoundary:${id}`,
			labelStack || null,
			errorToReport.name,
			errorToReport.message,
			errorToReport.stack,
		);
	}
	if (onError) {
		onError(location, errorToReport, event);
	}
}

/**
 * A robust component designed to enhance application stability by capturing and handling
 * JavaScript errors within the child component hierarchy. It logs errors for analysis,
 * displays a user-friendly fallback interface upon encountering an error, and supports
 * integration with experience tracking systems. This proactive error management approach
 * aims to improve overall user experience by addressing unexpected issues efficiently.
 */
export class ErrorBoundaryInner extends Component<ErrorBoundaryInnerProps, State> {
	static defaultProps: ErrorBoundaryInnerProps = {
		children: null,
		extraEventData: {},
		render: () => null,
		withExperienceTracker: false,
		onError: () => undefined,
		ufoInteractionId: null,
		ufoLabelStack: null,
	};

	// go/jfe-eslint
	// eslint-disable-next-line react/sort-comp
	static errorFrequencyMap: Map<string, number> = new Map();

	// go/jfe-eslint
	// eslint-disable-next-line react/sort-comp
	static logErrorToSplunk(hash: string | null | undefined, location: string, event: Event): void {
		let occurrences;
		if (hash !== undefined) {
			// @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
			occurrences = (this.errorFrequencyMap.get(hash) ?? 0) + 1;
			// @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
			this.errorFrequencyMap.set(hash, occurrences);
		} else {
			// If there's no hash, treat it as the first time
			occurrences = 1;
		}

		// Only send the error log if we are below the egress limit
		if (occurrences <= EGRESS_LIMIT) {
			log.safeErrorWithoutCustomerData(location, 'Unhandled error caught by error boundary', event);
		}
	}

	static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
		if (!!props.resetCaughtError && state.caughtError?.error === props.resetCaughtError) {
			return {
				...state,
				caughtError: null,
			};
		}
		return state;
	}

	state = {
		caughtError: null,
	};

	componentDidCatch(error: Error, info: ErrorInfo) {
		const { id, packageName, teamName, prefixOverride, onError, extraEventData } = this.props;
		this.setState({
			caughtError: {
				error,
				info,
			},
		});

		// passing this.props will cause "'onError' PropType is defined but prop is never used" eslint error
		reportError(
			error,
			info,
			{
				id,
				packageName,
				teamName,
				prefixOverride,
				onError,
				extraEventData,
			},
			this.props.ufoInteractionId,
			this.props.ufoLabelStack,
		);
	}

	render() {
		const { caughtError } = this.state;
		const { children, render: renderFallback, withExperienceTracker, extraEventData } = this.props;
		if (!caughtError) {
			return children;
		}
		const location = getLocation(this.props);
		return (
			<>
				{/* @ts-expect-error - TS2339 - Property 'error' does not exist on type 'never'. */}
				{renderFallback({ error: caughtError.error })}
				{withExperienceTracker && (
					<ViewExperienceFailureTracker
						location={location}
						failureEventAttributes={extraEventData}
					/>
				)}
			</>
		);
	}
}

export default ErrorBoundary;
